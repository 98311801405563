<template>
  <div class="common-wrap">
    <headNav active="1"></headNav>
    <div class="common-contents">
      <div class="artist-wrap">
        <div class="dot dot-left"></div>
        <div class="dot dot-right"></div>
        <!-- 彭家鹏 -->
        <div class="artist-item w1200">
          <div class="en-name en-name-1"></div>
          <div class="info flex between">
            <div class="left left-1">
              <div class="text-en-name" v-if="$i18n.locale == 'zh'">
                <div>PANG</div>
                <div>KAPANG</div>
              </div>
              <div class="text-name text-name-1">
                {{ $i18n.locale == "zh" ? "彭家鹏" : "PANG KAPANG" }}
              </div>
              <div class="text-line text-line-1"></div>
              <div class="text-1">
                <div>
                  {{
                    $i18n.locale == "zh"
                      ? "艺术总监兼"
                      : "Artistic Director And"
                  }}
                </div>
                <div>
                  {{ $i18n.locale == "zh" ? "首席指挥" : "Chief Conductor" }}
                </div>
              </div>
              <div class="text-2" v-html="$t('artistObj.level1')"></div>
            </div>
            <div class="right">
              <div class="text-en-name text-en-name-1">
                <div>PANG</div>
                <div>KAPANG</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 朱昌耀 -->
        <div class="artist-item w1200">
          <div class="en-name en-name-2"></div>
          <div class="info flex between">
            <div class="right right-2">
              <div class="text-en-name text-en-name-2">
                <div>ZHU</div>
                <div>CHANGYAO</div>
              </div>
            </div>
            <div class="left left-2">
              <div class="text-en-name" v-if="$i18n.locale == 'zh'">
                <div>ZHU</div>
                <div>CHANGYAO</div>
              </div>
              <div class="text-name text-name-2">
                {{ $i18n.locale == "zh" ? "朱昌耀" : "ZHU CHANGYAO" }}
              </div>
              <div class="text-line text-line-2"></div>
              <div class="text-1">
                <div>
                  {{
                    $i18n.locale == "zh" ? "艺术指导" : "Artistic Instructor"
                  }}
                </div>
              </div>
              <div class="text-2" v-html="$t('artistObj.level2')"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 乐手 -->
      <div class="bandsman-wrap">
        <div class="bandsman-list tac">
          <div class="w1200">
            <div class="flex tips center pt32">
              <img src="@/assets/click.png" />
              <div class="pl8 clight">{{ $t("bandsman.seeDetails") }}</div>
            </div>
            <!-- 中文 -->
            <div v-if="$i18n.locale == 'zh'">
              <ul
                v-for="(item, index) in listData"
                :key="index"
                class="row flex center"
                :class="[`row${index}`]"
              >
                <li
                  v-for="(sub, key) in item"
                  :key="key"
                  @click="listClick(index, key, sub.title, sub.py)"
                  class="sub cmain"
                  :class="getActive(index, key)"
                >
                  <p class="title">{{ sub.title }}</p>
                  <p class="py pinyin">{{ sub.pinyin }}</p>
                </li>
              </ul>
            </div>
            <!-- 英文 -->
            <div v-else>
              <ul
                v-for="(item, index) in listData"
                :key="index"
                class="row flex center"
                :class="[`row${index}`]"
              >
                <li
                  v-for="(sub, key) in item"
                  :key="key"
                  @click="listClick(index, key, sub.title, sub.py)"
                  class="sub cmain"
                  :class="getActive(index, key)"
                  style="line-height: 100px"
                >
                  <div
                    class="title"
                    style="
                      line-height: 25px;
                      text-align: center;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <div style="margin: 0 auto">{{ sub.py }}</div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="tac" style="padding-top: 100px">
              <img style="width: 24px" src="../assets/down.png" alt="" />
              <p class="fs16 clight">{{ $t("bandsman.dropDown") }}</p>
            </div>
          </div>
        </div>
        <div class="bandsman-contents mt40" v-if="activeList.length">
          <div class="down">
            <img src="../assets/down.png" alt="" />
          </div>
          <div class="list flex wrap">
            <div
              class="item flex between"
              v-for="(item, key) in activeList"
              :key="key"
            >
              <div class="pic">
                <img :src="`https://api.sucoch.com/cms${item.thumb}`" alt="" />
                <span class="tips" v-if="item.post && $i18n.locale == 'zh'">{{
                  item.post
                }}</span>
              </div>
              <div class="text pl20 flex-1 tal" v-html="splitName(item)"></div>
            </div>
          </div>
          <div class="active-m-i">
            {{ currentMusicalInstrument }}
          </div>
        </div>
      </div>
    </div>
    <footNav />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
export default {
  components: { headNav, footNav },
  data() {
    return {
      currentMusicalInstrument: "HAHA", // 当前点击的乐器名称
      active: [-1, -1],
      tag: "",
      members: [],
      listData: [
        [
          {
            title: "打击乐",
            py: "Percussion",
            pinyin: "DaJiYue",
            active: false,
          },
        ],
        [
          {
            title: "高音笙",
            py: "Soprano Sheng",
            pinyin: "GaoYinSheng",
            active: false,
          },
        ],
        [
          {
            title: "梆笛",
            py: "Bangdi",
            pinyin: "BangDi",
            active: false,
          },
          {
            title: "曲笛",
            py: "Qudi",
            pinyin: "QuDi",
            active: false,
          },
          {
            title: "新笛",
            py: "Xindi",
            pinyin: "XinDi",
            active: false,
          },
          {
            title: "倍低音笙",
            py: "Contrabass Sheng",
            pinyin: "BeiDiYinSheng",
            active: false,
          },
          {
            title: "中音笙",
            py: "Alto Sheng",
            pinyin: "ZhongYinSheng",
            active: false,
          },
          {
            title: "次中音笙",
            py: "Tenor sheng",
            pinyin: "CiZhongYinSheng",
            active: false,
          },
          {
            title: "低音笙",
            py: "Bass Sheng",
            pinyin: "DiYinSheng",
            active: false,
          },

          {
            title: "低音唢呐",
            py: "Bass Suona",
            pinyin: "DiYinSuoNa",
            active: false,
          },
          {
            title: "中音唢呐",
            py: "Alto Suona",
            pinyin: "ZhongYinSuoNa",
            active: false,
          },
          {
            title: "高音唢呐",
            py: "Soprano Suona",
            pinyin: "GaoYinSuoNa",
            active: false,
          },
        ],
        [
          {
            title: "古筝",
            py: "Guzheng",
            pinyin: "GuZheng",
            active: false,
          },
          {
            title: "扬琴",
            py: "Yangqin",
            pinyin: "YangQin",
            active: false,
          },
          {
            title: "琵琶",
            py: "Pipa",
            pinyin: "PiPa",
            active: false,
          },
          {
            title: "中阮",
            py: "Zhongruan",
            pinyin: "ZhongRuan",
            active: false,
          },
          {
            title: "大阮",
            py: "Daruan",
            pinyin: "DaRuan",
            active: false,
          },
          {
            title: "三弦",
            py: "Sanxian",
            pinyin: "SanXian",
            active: false,
          },
          {
            title: "竖琴",
            py: "Harp",
            pinyin: "ShuQin",
            active: false,
          },
        ],
        [
          {
            title: "高胡",
            py: "Gaohu",
            pinyin: "GaoHu",
            active: false,
          },
          {
            title: "中胡",
            py: "Zhonghu",
            pinyin: "ZhongHu",
            active: false,
          },
          {
            title: "柳琴",
            py: "Liuqin",
            pinyin: "LiuQin",
            active: false,
          },
          {
            title: "大提琴",
            py: "Cello",
            pinyin: "DaTiQin",
            active: false,
          },
          {
            title: "低音提琴",
            py: "Double Bass",
            pinyin: "DiYinTiQin",
            active: false,
          },
          {
            title: "二胡",
            py: "Erhu",
            pinyin: "ErHu",
            active: false,
          },
        ],
      ],
    };
  },
  created() {
    this.getMembers();
  },
  computed: {
    activeList() {
      let list = [];
      if (this.tag && this.members.length) {
        list = this.members.filter((item) => {
          let tagName = item.tags.split(",")[0];
          if (tagName.indexOf("/") == -1) {
            return (
              item.tags.indexOf(this.tag) > -1 &&
              item.tags.indexOf(this.tag) == 0
            );
          } else {
            return item.tags.indexOf(this.tag) > -1;
          }
        });
      }
      return list;
    },
    state() {
      return this.$store.state;
    },
  },
  methods: {
    listClick(index, key, tag, py) {
      this.active = [index, key];
      this.tag = tag;
      this.currentMusicalInstrument = py;
    },
    getActive(index, key) {
      if (this.active[0] == index && this.active[1] == key) {
        return `active sub${key}`;
      } else {
        return `sub${key}`;
      }
    },

    getMembers() {
      this.$ajax
        .get("https://api.sucoch.com/cms/cms/api/member", {})
        .then((res) => {
          if (res.data && res.data.length) {
            this.members = res.data;
          }
        });
    },
    splitName(item) {
      if (!item.description) return "";
      let tem = [];
      let tem2 = [];
      let string = "";
      tem = item.description.split("\n");
      tem2 = tem[0].split("（");
      if (this.$i18n.locale == "zh") {
        if (tem2.length > 1) {
          string = `<h3 class="cmain fs20 mb4">${tem2[0]}</h3>
        <div class="cmain fs12 mb12">（${tem2[1]}</div> 
        <h3 class="clighter fs16">${tem[1] ? tem[1] : ""}</h3>
        `;
        } else {
          string = `<h3 class="cmain fs20">${tem[0]}</h3>
        <h3 class="clighter fs16">${tem[1] ? tem[1] : ""}</h3>
        `;
        }
      } else {
        string = `<h3 class="cmain fs20 mb4">${tem[1] ? tem[1] : ""}</h3>`;
      }
      return string;
    },
  },
};
</script>

<style scoped lang="scss">
.common-contents {
  background: #f2f6f7;
  padding: 95px 0 125px;
  .banner {
    .img {
      width: 100%;
      max-height: 682px;
    }
  }
  .artist-wrap {
    position: relative;
    .dot {
      width: 278px;
      height: 526px;
      position: absolute;
      z-index: 99;
      background-image: url("../assets/version2/other-13.png");
      background-size: 100% 100%;
    }
    .dot-left {
      left: 0;
      top: 134px;
    }
    .dot-right {
      right: 0;
      bottom: 59px;
      transform: rotateY(180deg);
    }
    .artist-item {
      margin-bottom: 95px;
      .en-name {
        height: 336px;
        background-size: 100% 100%;
        position: relative;
        z-index: 0;
      }
      .en-name-1 {
        width: 855px;
        margin-left: 96px;
        background-image: url("../assets/version2/other-9.png");
      }
      .en-name-2 {
        width: 1200px;
        margin-left: 32px;
        background-image: url("../assets/version2/other-10.png");
      }
      .info {
        margin-top: -70px;
        .left {
          width: 580px;
          margin-right: 76px;
          position: relative;
          .text-en-name {
            font-size: 18px;
            font-family: Playfair Display SC, Playfair Display SC-Regular;
            font-weight: 400;
            color: #666666;
            line-height: 18px;
            letter-spacing: 2px;
            margin-top: 20px;
          }
          .text-name {
            font-size: 64px;
            font-weight: 300;
            color: #333333;
            letter-spacing: 2px;
            margin-top: 5px;
            position: relative;
          }
          .text-name-1:after,
          .text-name-2::before {
            content: "";
            position: absolute;
            bottom: 19px;
            z-index: 99;
            width: 11px;
            height: 10px;
            background: #b49a71;
            display: inline-block;
          }
          .text-name-1:after {
            margin-left: 8px;
          }
          .text-name-2::before {
            left: 360px;
          }
          .text-line {
            margin-top: 45px;
            width: 95px;
            height: 0px;
            border: 0.3px solid #b9996b;
            background: #b9996b;
            margin-left: -15px;
            display: inline-block;
          }
          .text-line-1 {
            transform: rotate(130deg);
          }
          .text-line-2 {
            transform: rotate(-135deg);
          }
          .text-1 {
            font-size: 24px;
            font-weight: 400;
            color: #333333;
            line-height: 35px;
            letter-spacing: 2px;
            margin-top: 62px;
          }
          .text-2 {
            margin-top: 45px;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 34px;
            letter-spacing: 1px;
            text-align: left;
          }
        }
        .left-1 {
          text-align: left;
        }
        .left-2 {
          text-align: right;
        }
        .right {
          width: 491px;
          height: 649px;
          background-image: url("../assets/version2/other-11.png");
          background-size: 100% 100%;
          position: relative;
          .text-en-name {
            position: absolute;
            bottom: 34px;
            z-index: 99;
            font-size: 36px;
            font-family: Playfair Display SC, Playfair Display SC-Regular;
            font-weight: 400;
            color: #b5b5b5;
            line-height: 36px;
            letter-spacing: 3px;
          }
          .text-en-name-1 {
            right: -20px;
            text-align: right;
          }
        }
        .right-2 {
          background-image: url("../assets/version2/other-12.png");
          .text-en-name-2 {
            left: -14px;
            text-align: left;
          }
        }
      }
    }
  }
  .bandsman-list {
    height: 780px;
    background: url("../assets/bandsman-title-bg.jpg") no-repeat 0 0;
    background-size: 100% 100%;
    font-size: 20px;
    .sub {
      margin: 0 0px;
      font-weight: bold;
      cursor: pointer;
      width: 180px;
      height: 100px;
      position: absolute;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 100;
      p {
        margin-bottom: 0px;
      }

      &.active {
        .title {
          background: #b49a71;
          border-radius: 200px;
          width: 100px;
          height: 100px;
          line-height: 100px;
          color: #fff;
          font-size: 20px;
          text-shadow: 0 0 0;
        }
        .py {
          display: none;
        }
      }
    }
    .row {
      position: relative;
      width: 100%;
      height: 100px;
    }

    .row0,
    .row1 {
      .sub0 {
        height: 100px;
        bottom: -55px;
      }
    }

    .row0,
    .row1,
    .row2 {
      text-shadow: 0 0 12px #ca3e24;
    }
    .row3,
    .row4 {
      text-shadow: 0 0 12px #e37f14;
    }

    .pinyin {
      font-weight: 400;
      font-size: 16px;
    }

    .row0,
    .row1 {
      margin-bottom: 0;
      .sub0 {
        left: 50%;
        margin-left: -90px;
      }
    }
    .row2 {
      @for $i from 0 through 4 {
        $l: $i * 150 + 1160;
        .sub#{4-$i} {
          right: #{$l}px;
        }
      }
      @for $i from 0 through 4 {
        $l: $i * 150 + 600;
        .sub#{$i + 5} {
          left: #{$l}px;
        }
      }
      .sub0,
      .sub9 {
        bottom: -320px;
      }
      .sub1,
      .sub8 {
        bottom: -240px;
      }
      .sub2,
      .sub7 {
        bottom: -170px;
      }
      .sub3,
      .sub6 {
        bottom: -90px;
      }

      .sub4,
      .sub5 {
        bottom: -30px;
      }

      .sub0 {
        right: 1160px;
      }
      .sub1 {
        right: 1060px;
      }
      .sub2 {
        right: 920px;
      }
      .sub3 {
        right: 780px;
      }
      .sub4 {
        right: 620px;
      }
      .sub5 {
        left: 620px;
      }
      .sub6 {
        left: 780px;
      }
      .sub7 {
        left: 940px;
      }
      .sub8 {
        left: 1050px;
      }
      .sub9 {
        left: 1147px;
      }
    }

    .row3 {
      @for $i from 0 through 2 {
        $l: $i * 150 + 650;
        .sub#{2-$i} {
          right: #{$l}px;
        }
      }
      @for $i from 0 through 2 {
        $l: $i * 150 + 650;
        .sub#{$i + 4} {
          left: #{$l}px;
        }
      }

      .sub0,
      .sub6 {
        bottom: -200px;
      }
      .sub1,
      .sub5 {
        bottom: -100px;
      }
      .sub2,
      .sub4 {
        bottom: -50px;
      }
    }

    .row4 {
      @for $i from 0 through 2 {
        $l: $i * 130 + 540;
        .sub#{2-$i} {
          right: #{$l}px;
        }
      }
      @for $i from 0 through 3 {
        $l: $i * 130 + 540;
        .sub#{$i + 3} {
          left: #{$l}px;
        }
      }
      .sub2 {
        right: 570px;
      }
      .sub3 {
        left: 570px;
      }
      .sub0,
      .sub5 {
        bottom: -120px;
      }
      .sub1,
      .sub4 {
        bottom: -50px;
      }
      .sub2,
      .sub3 {
        bottom: 0px;
      }
    }
  }

  .bandsman-contents {
    width: 1500px;
    padding: 0 120px 10px;
    background: #dadcdf 90px 100px;
    margin: 0px auto;
    border-radius: 12px;
    position: relative;

    .down {
      padding: 40px 0 100px;
    }

    .item {
      width: 315px;
      .pic {
        width: 180px;
        height: 180px;
        overflow: hidden;
        position: relative;
        img {
          width: 100%;
          height: auto;
        }
        .tips {
          height: 26px;
          position: absolute;
          left: 0;
          bottom: 0;
          color: #fff;
          line-height: 26px;
          padding-left: 8px;
          padding-right: 32px;
          text-align: left;
          background-image: linear-gradient(
            to right,
            rgba(179, 154, 113, 1) 50%,
            rgba(179, 154, 113, 0) 95%
          );
        }
      }
      margin-bottom: 90px;
    }
    .list {
      position: relative;
      z-index: 1;
    }
    .active-m-i {
      font-size: 125px;
      font-weight: bolder;
      -webkit-text-stroke: 1px #c4c4c4;
      color: #dbe2e2;
      opacity: 0.4;
      position: absolute;
      top: 58px;
      left: 80px;
      z-index: 0;
    }
  }
}
</style>
